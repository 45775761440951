import React from "react"
import Cookies from "js-cookie"
import Snackbar from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles, Paper } from "@material-ui/core"
import { whenMobile } from "@punks/ui-mui"

declare const window: EventTarget
declare const document: Document

interface Props {
  componentType?: "Dialog" | "Snackbar"
  cookieName: string
  cookieValue: string | any
  acceptOnScroll?: boolean
  acceptOnScrollPercentage?: number
  onAccept?: () => void | null
  expires?: number | Date
  hideOnAccept?: boolean
  children?: React.ReactNode
  title?: string | null
  message?: React.ReactNode
  acceptButtonLabel?: string
  debug?: boolean
  extraCookieOptions?: any
  snackbarAnchor?: {
    horizontal: "left" | "center" | "right"
    vertical: "top" | "bottom"
  }
  actions?: React.ReactNode
}
type State = {
  visible: boolean
}

interface CookiesSnackbarProps {
  anchorOrigin: any
  open: boolean
  onClick: () => void
  message: React.ReactNode
  acceptButtonLabel: React.ReactNode
}

const useSnackabStyles = makeStyles((theme) => ({
  root: {
    bottom: 42,
    [whenMobile(theme)]: {
      bottom: 2,
    },
  },
  content: {
    padding: theme.spacing(1.5, 2.5),
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)",

    [whenMobile(theme)]: {
      textAlign: "center",
      flexDirection: "column",
      padding: theme.spacing(1),
    },
  },
  button: {
    marginLeft: theme.spacing(1),

    [whenMobile(theme)]: {
      marginTop: theme.spacing(0.5),
      marginLeft: 0,
    },
  },
}))

const CookiesSnackbar = ({
  anchorOrigin,
  open,
  onClick,
  message,
  acceptButtonLabel,
}: CookiesSnackbarProps) => {
  const classes = useSnackabStyles()
  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open} className={classes.root}>
      <Paper className={classes.content}>
        <div>{message}</div>
        <div>
          <Button
            key="accept"
            color="secondary"
            variant="contained"
            onClick={onClick}
            className={classes.button}
          >
            {acceptButtonLabel}
          </Button>
        </div>
      </Paper>
    </Snackbar>
  )
}

export default class CookieBar extends React.Component<Props, State> {
  static defaultProps = {
    componentType: "Snackbar",
    cookieValue: "",
    acceptOnScroll: false,
    acceptOnScrollPercentage: 25,
    expires: 10000000000000,
    hideOnAccept: true,
    debug: false,
    extraCookiesOptions: undefined,
    snackbarAnchor: { horizontal: "center", vertical: "bottom" },
    children: null,
    title: null,
    acceptButtonLabel: "Accept",
    actions: null,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  componentDidMount() {
    const { cookieName, debug, acceptOnScroll } = this.props

    if (Cookies.get(cookieName) === undefined || debug) {
      this.setState({ visible: true })
    }

    if (window && acceptOnScroll) {
      window.addEventListener("scroll", this.handleScroll, { passive: true })
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  /**
   * checks whether scroll has exceeded set amount and fire accept if so.
   */
  handleScroll = () => {
    const { acceptOnScrollPercentage } = this.props
    if (document && typeof acceptOnScrollPercentage === "number") {
      const rootNode = document.documentElement || document.body

      if (rootNode) {
        // (top / (height - height)) * 100
        const percentage =
          (rootNode.scrollTop /
            (rootNode.scrollHeight - rootNode.clientHeight)) *
          100

        if (percentage > acceptOnScrollPercentage) {
          this.handleAccept()
        }
      }
    }
  }

  /**
   * Set a persistent cookie
   */
  handleAccept = () => {
    const {
      cookieName,
      cookieValue,
      expires,
      hideOnAccept,
      onAccept,
      extraCookieOptions,
    } = this.props

    if (onAccept) {
      onAccept()
    }

    if (window) {
      window.removeEventListener("scroll", this.handleScroll)
    }

    Cookies.set(cookieName, cookieValue, { expires, ...extraCookieOptions })

    if (hideOnAccept) {
      this.setState({ visible: false })
    }
  }

  render() {
    const {
      componentType,
      children,
      message,
      snackbarAnchor,
      title,
      acceptButtonLabel,
      actions,
    } = this.props

    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child as any, { onAccept: this.handleAccept })
    )

    switch (componentType) {
      case "Snackbar":
        return (
          <CookiesSnackbar
            anchorOrigin={snackbarAnchor}
            open={this.state.visible}
            onClick={this.handleAccept}
            message={message}
            acceptButtonLabel={acceptButtonLabel}
          />
        )
      case "Dialog":
        return (
          <Dialog open={this.state.visible}>
            {children ? (
              childrenWithProps
            ) : (
              <>
                {title ? <DialogTitle>{title}</DialogTitle> : null}
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    component="div"
                  >
                    {message}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {actions}
                  <Button onClick={this.handleAccept} color="primary">
                    {acceptButtonLabel}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        )
      default:
        return null
    }
  }
}
