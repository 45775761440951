import { graphql } from "gatsby"
import React from "react"
import { withPreview } from "@punks/gatsby"
import { SanityPage } from "@punks/contents-sanity"
import LandingPageContainer from "../containers/editorial/LandingPage"
import { LandingPageQuery } from "../__generated__/LandingPageQuery"

interface Props {
  data: LandingPageQuery
}

const LandingPage = ({ data }: Props) => {
  return (
    <SanityPage page={data.sanityPage}>
      <LandingPageContainer pageData={data.sanityPage?.data as any} />
    </SanityPage>
  )
}

export default withPreview(LandingPage)

export const pageQuery = graphql`
  query LandingPageQuery($id: String!) {
    sanityPage(_id: { eq: $id }) {
      _id
      data {
        _rawBody(resolveReferences: { maxDepth: 10 })
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        imageLink {
          _id
        }
        imageSubtitle
        imageSubtitleLink {
          href
          blank
        }
      }
    }
  }
`
