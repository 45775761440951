import React from "react"
import HomeButton from "../../../../features/navigation/components/HomeButton"
import HeaderBar from "../../../../ui/organisms/HeaderBar"

export interface HeaderProps {
  leftControl?: React.ReactNode
  title?: React.ReactNode
  rightControl?: React.ReactNode
}

const Header = ({ leftControl, title, rightControl }: HeaderProps) => {
  return (
    <HeaderBar
      leftControl={leftControl ?? <HomeButton />}
      title={title}
      rightControl={rightControl}
    />
  )
}

export default Header
