import React from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"
import { Link } from "@punks/core"
import { CustomTypography } from "@punks/ui-mui"
import Header, { HeaderProps } from "./components/Header"
import Footer from "./components/Footer"
import CookieBar from "./components/CookieBar"
import Logo from "./components/Logo"

interface Background {
  image: string
  texture?: boolean
}

interface Props {
  background?: Background
  children: any
  header?: HeaderProps
  noFooter?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {},
  content: {
    paddingTop: 64,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minHeight: "100vh",
  },
  mainContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    maxHeight: "46px",
  },
  footer: {
    position: "relative",
  },
  background: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundImage: ({ background }: any) =>
      background?.image ? `url(${background.image})` : undefined,
    zIndex: -10,
  },
  textureBackground: {
    backgroundRepeat: "repeat",
  },
}))

const DefaultLayout = ({ header, background, children, noFooter }: Props) => {
  const classes = useStyles({
    background,
  })
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header
          leftControl={
            <Link to="/">
              <Logo className={classes.logo} />
            </Link>
          }
          {...header}
        />
      </div>
      <main className={classes.content}>
        <div
          className={classNames({
            [classes.background]: background !== undefined,
            [classes.textureBackground]: background?.texture,
          })}
        />
        <div className={classes.mainContent}>{children}</div>
        {!noFooter && (
          <div className={classes.footer}>
            <Footer style="transparent" text="primary" />
          </div>
        )}
      </main>
      <CookieBar
        cookieName="bloxCookiesAccepted"
        message={
          <CustomTypography>
            {
              "Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili come specificato nella cookie policy. Puoi acconsentire all'utilizzo di tali tecnologie chiudendo questa informativa"
            }
          </CustomTypography>
        }
        acceptButtonLabel="Accetta"
      />
    </div>
  )
}

export default DefaultLayout
