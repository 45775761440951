import React from "react"
import { useStaticContent } from "@punks/core"
import { LayoutContents } from "../../../../wrapRoot/contents/layout"

interface Props {
  className?: string
}

const Logo = ({ className }: Props) => {
  const layout = useStaticContent(LayoutContents)
  return layout?.data?.header?.logo?.asset?.url ? (
    <img className={className} src={layout?.data.header.logo.asset.url} />
  ) : (
    <></>
  )
}

export default Logo
