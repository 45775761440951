import { Container, makeStyles } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

interface Props {
  children: any
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}))

const CustomContainer = ({ children, className }: Props) => {
  const classes = useStyles()
  return (
    <Container className={classNames(classes.root, className)}>
      {children}
    </Container>
  )
}

export default CustomContainer
