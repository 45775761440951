import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { RichTextField, ImageField, LinkField } from "@punks/core"
import DefaultLayout from "../../../layouts/DefaultLayout"
import { LandingPageQuery_sanityPage_data as LandingPageData } from "../../../__generated__/LandingPageQuery"
import CustomContainer from "../../../ui/spacings/CustomContainer"

interface Props {
  pageData: LandingPageData
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  imgContainer: {
    marginTop: theme.spacing(4),
  },
  imgSubtitle: {
    marginTop: theme.spacing(1),
  },
}))

const LandingPageContainer = ({ pageData }: Props) => {
  const classes = useStyles()
  return (
    <DefaultLayout>
      <CustomContainer className={classes.container}>
        <RichTextField value={pageData._rawBody} color="primary" variant="h2" />
        <Grid container justify="center" className={classes.imgContainer}>
          <Grid item xs={12} sm={6}>
            <LinkField value={pageData.imageLink}>
              <ImageField value={pageData.image} />
            </LinkField>
            <LinkField value={pageData.imageSubtitleLink}>
              <div className={classes.imgSubtitle}>
                {pageData.imageSubtitle}
              </div>
            </LinkField>
          </Grid>
        </Grid>
      </CustomContainer>
    </DefaultLayout>
  )
}

export default LandingPageContainer
