import React from "react"
import HomeIcon from "@material-ui/icons/Home"
import { IconButton } from "@material-ui/core"
import { navigate } from "@punks/core"

interface Props {
  icon?: React.ReactNode
  homeUrl?: string
}

const HomeButton = ({ icon, homeUrl }: Props) => {
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="home"
      onClick={() => navigate(homeUrl ?? "/")}
    >
      {icon ?? <HomeIcon />}
    </IconButton>
  )
}

export default HomeButton
