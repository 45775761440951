import React from "react"
import { AppBar, makeStyles, Toolbar } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}))

interface Props {
  leftControl?: React.ReactNode
  title?: React.ReactNode
  rightControl?: React.ReactNode
}

const HeaderBar = ({ leftControl, title, rightControl }: Props) => {
  const classes = useStyles()
  return (
    <AppBar position="fixed">
      <Toolbar>
        {leftControl}
        <div className={classes.title}>{title}</div>
        {rightControl}
      </Toolbar>
    </AppBar>
  )
}

export default HeaderBar
